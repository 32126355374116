import React from "react";
import styles from './NotificationTrigger.module.scss'
import { DEBUG } from "../../../../../config";
import Button from "../../../../../components/ripple/Button";
import { dispatchNotification } from "../../notificationSystem/notifications";

export default function NotificationTrigger({}) {

    if (!DEBUG) return null;

    return (
        <Button
            aria-label='Notifications'
            className={styles['trigger']}
            icon='far fa-bell'
            displayType='text'
            onClick={() => {
                dispatchNotification({
                    message: 'This is a dev placeholder for future updates. No functionality for now.'
                })
            }}
            visuals={{
                colorBasic: ['var(--colorFontLight)'],
                borderRadius: '50%',
            }}
        />
    )
}