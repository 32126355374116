import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
    setCssVariables, setThemeVariables,
    RESOLUTION_BREAKPOINTS,
    DARK_THEME_INDEX, SYSTEM_THEME_INDEX,
    ANIMATIONS_UI, UI_FONTS_DESKTOP, UI_FONTS_STATIC, UI_GEOMETRY,
    LS_THEME_NAME, LS_THEME_VERSION_NAME, VERSION_ENGINE_THEME,
} from "../../../helpers/ui/ui";
import { updateLayoutDimensions, updateTheme, updateUiData } from "../../../redux/actions/UIActions";

function UiHandler({theme, dispatch, width, height, mobile, touch, screen}) {

    // theme.type needs to be stored for matchMedia.onchange to see the actual type value
    const themeType = useRef(theme?.type);
    const dimensionsTimeout = useRef(0);

    const darkThemeMediaQuery = typeof window !== 'undefined' ? window.matchMedia("(prefers-color-scheme: dark)") : null;
    const themeVersion = typeof window !== 'undefined'
        ? !isNaN(parseFloat(localStorage.getItem(LS_THEME_VERSION_NAME))) ? parseFloat(localStorage.getItem(LS_THEME_VERSION_NAME)) : 0
        : null;
    // reset stored UI data in local storage if required after new update (set higher version to trigger reset)
    const storedThemeData = typeof window !== 'undefined'
        ? themeVersion < VERSION_ENGINE_THEME ? {} : JSON.parse(localStorage.getItem(LS_THEME_NAME) || '{}')
        : null;

    const setDeviceResolution = timeout => {
        clearTimeout(dimensionsTimeout.current);
        dimensionsTimeout.current = setTimeout(
            () => {
                dispatch(updateLayoutDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                }))
            }, typeof timeout === 'number' ? timeout : 200
        )
    };

    const setDeviceSpecifications = () => {

        const touch = window?.navigator?.userAgentData?.mobile || window.innerWidth < RESOLUTION_BREAKPOINTS.tablet;
        const mobile = touch;
        let screen = 'desktop';

        switch (true) {
            case window.innerWidth <= RESOLUTION_BREAKPOINTS.phone:
                screen = 'phone';
                break;
            case window.innerWidth > RESOLUTION_BREAKPOINTS.phone && window.innerWidth <= RESOLUTION_BREAKPOINTS.tablet:
                screen = 'tablet';
                break;
            case window.innerWidth > RESOLUTION_BREAKPOINTS.tablet && window.innerWidth <= RESOLUTION_BREAKPOINTS.desktop:
                screen = 'desktop';
                break;
            case window.innerWidth > RESOLUTION_BREAKPOINTS.desktop:
                screen = 'large';
                break;
        }

        dispatch(updateUiData({mobile, touch, screen}));
        document.documentElement.setAttribute('data-device', mobile ? 'mobile' : 'desktop');
        document.documentElement.setAttribute('data-touch', touch);
        document.documentElement.setAttribute('data-screen', screen);
    };

    useEffect(() => {
        // set new available theme engine version
        if (themeVersion !== VERSION_ENGINE_THEME) localStorage.setItem(LS_THEME_VERSION_NAME, VERSION_ENGINE_THEME.toString());
        setDeviceResolution(0);
        // listen to device resolution change with timout to avoid excessive updates
        window.addEventListener('resize', setDeviceResolution);
        // set user selected theme or set default if no selection is found
        // const storedThemeData = JSON.parse(localStorage.getItem('theme') || '{}');
        dispatch(updateTheme({...(!!storedThemeData?.type ? storedThemeData : {type: SYSTEM_THEME_INDEX})}));
        // set basic UI
        setCssVariables(UI_GEOMETRY);
        setCssVariables(UI_FONTS_STATIC);
        setCssVariables(UI_FONTS_DESKTOP);
        setCssVariables(ANIMATIONS_UI);
        // enable animations with some delay
        // setTimeout(() => setCssVariables(ANIMATIONS_UI), 2000);
        // console.log(window?.navigator)
    }, []);
    // update device specs on init and resolution change
    useEffect(() => setDeviceSpecifications(), [width, height]);
    // handle theme calculations when user switches theme
    useEffect(() => {
        // Answer to redux theme change by setting theme to localStorage
        localStorage.setItem(LS_THEME_NAME, JSON.stringify({...storedThemeData, ...{type: theme.type}}));
        // store theme.type for matchMedia.onchange to see the actual type value, otherwise it will always see only the initial one
        themeType.current = theme.type;
        //
        if (theme?.type === SYSTEM_THEME_INDEX) {
            setThemeVariables(darkThemeMediaQuery.matches);
        } else {
            setThemeVariables(theme?.type === DARK_THEME_INDEX);
        }
    }, [theme.type]);
    // listen to system theme change
    useEffect(() => {
        darkThemeMediaQuery.onchange = e => {
            if (themeType.current === SYSTEM_THEME_INDEX) setThemeVariables(e.matches);
        }
    }, []);

    return null
    // return (
    //     <div>
    //         {/*<p>{window}</p>*/}
    //         {/*<p>{window?.navigator}</p>*/}
    //         {/*<p>{window?.navigator?.userAgentData}</p>*/}
    //         {/*<p>{window?.navigator?.userAgentData?.mobile}</p>*/}
    //     </div>
    // )
}

const mapStateToProps = state => ({
    theme: state.UI.theme,
    mobile: state.UI.mobile,
    screen: state.UI.screen,
    touch: state.UI.touch,
    user: state.userData.user,
    width: state.UI.layoutDimensions.width,
    height: state.UI.layoutDimensions.height,
});

export default connect(mapStateToProps)(UiHandler);