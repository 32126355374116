import { ENV_LOCAL, NAV_STACK_LIST, URL_BASE, withoutBaseURL } from "../config";
import { generateSlug } from "../helpers/common";

export const URL_CUSTOM_STACK = !ENV_LOCAL ? `${URL_BASE}/stacker` : '/stacker';
export const CUSTOM_SERVERS_COUNT_KEY = 'servers_count';
export const CUSTOM_SERVERS_COUNT_DEFAULT = 3;
export const CUSTOM_SERVERS_COUNT_OPTIONS = [1, 3, 5, 7];
export const CUSTOM_STACK_NAME = 'Stack builder';
export const NAV_CUSTOM_STACK = `/${generateSlug(CUSTOM_STACK_NAME)}`;
export const NAV_BUILDER_FLOW_NAME = `Builder`;
export const NAV_BUILDER_FLOW = `${NAV_STACK_LIST}${NAV_CUSTOM_STACK}/${generateSlug(NAV_BUILDER_FLOW_NAME)}`;

export const LOCAL_STORAGE_NAME_BUILDER_PROJECTS = 'builder_projects';

// requests

export const API_BUILDER_EXTERNAL = `${URL_BASE}/stacker`;
export const API_BUILDER_INTERNAL = `/api/builder`;
export const API_BUILDER_DEPLOYMENTS_GET = `${API_BUILDER_INTERNAL}/deployments`;
export const API_BUILDER_PROJECTS_GET = `${API_BUILDER_INTERNAL}/projects`;
// project
export const URL_BUILDER_PROJECT_EXTERNAL = `${API_BUILDER_EXTERNAL}/project`;
export const URL_BUILDER_PROJECT_INTERNAL = `${API_BUILDER_INTERNAL}/project`;

export const URL_BUILDER_DEPLOY_INTERNAL = `${API_BUILDER_INTERNAL}/deploy`;
// cloud
export const URL_BUILDER_TOKEN_EXTERNAL = `${API_BUILDER_EXTERNAL}/cloud`;
export const URL_BUILDER_TOKEN_INTERNAL = `${API_BUILDER_INTERNAL}/tokens`;

// flow config

export const NAV_PROJECT_DEPLOYMENTS_URL = 'deployments';
export const NAV_PROJECT_DEPLOYMENTS_NAME = 'Deployments';

export const NAV_PROJECT_BUILDER_URL = 'builder';
export const NAV_PROJECT_BUILDER_NAME = 'Builder';

export const NAV_PROJECT_DEPLOYMENT_URL = 'cloud';
export const NAV_PROJECT_DEPLOYMENT_NAME = 'Cloud setup';

export const NAV_PROJECT_COMPOSE_URL = 'deploy';
export const NAV_PROJECT_COMPOSE_NAME = 'Deploy';

export const BUILDER_DATA = {
    name: CUSTOM_STACK_NAME,
    code: generateSlug(CUSTOM_STACK_NAME),
    icon: {
        dark: {image: '/images/builder/builder-logo-dark.svg', isAsset: true},
        light: {image: '/images/builder/builder-logo-light.svg', isAsset: true},
    },
    accessibility: {},
    ram_size: '4Gb',
    disk_size: '40Gb',
    cpu: 2,
    plan: '1',
    features: [],
    apps: [],
    services: [],
    price: [{currency: "USD", value: 200000}],
}