import React from "react";
import Link from "next/link";
import styles from "./Logo.module.scss";
import ImgCDN from "../../../../../components/ImgCDN";

export default function Logo() {
    return (
        (<Link href='/' className={styles['logo']} draggable='false' tabIndex='0'>

            <ImgCDN
                className={styles['logo__image']} src="/img/logo.svg"
                alt='try.direct'
                draggable='false'
            />
            <ImgCDN
                className={styles['logo__brand']} src="/img/logo-brand.svg"
                alt='try.direct'
                draggable='false'
            />

        </Link>)
    );
}