import React from "react";
import style from './HeaderDropdown.module.scss'
import RippleLayout from "../../../../../../components/ripple/RippleLayout";
import Link from 'next/link'
import Button from "../../../../../../components/ripple/Button";

export default class HeaderDropdown extends React.Component { // must be redone for reuse if case will appear
    // a separate class was created to avoid logic with dropdown indexes. one class serves one dropdown, so it's pretty simple
    constructor(props) {
        super(props);

        this.state = {
            isDropdownActive: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isDropdownActive !== prevState.isDropdownActive) {
            if (this.state.isDropdownActive) {
                document.addEventListener('click', this.handleDropdownClick);
            } else if (!this.state.isDropdownActive) {
                document.removeEventListener('click', this.handleDropdownClick);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdownClick);
    }

    handleDropdownClick = e => {
        if (!this.dropdown.contains(e.target) && !this.trigger.contains(e.target)) this.setState({isDropdownActive: false})
    };

    render() {

        let {nav, navIndex} = this.props;
        let stateClass = this.state.isDropdownActive ? ` ${style['header-dropdown--open']}` : '';

        return (
            <div className={`${style['header-dropdown']}${stateClass}`} key={navIndex}>
                <RippleLayout
                    className={style['header-dropdown__ripple']}
                    visuals={{colorRippleTint: ['var(--colorFontLight)'], opacityHover: ['0'], borderRadius: '0'}}
                    tabIndex='-1'
                >
                    <button
                        className={style['header-dropdown__ripple__trigger']}
                        aria-label={`Open ${nav.title} dropdown`}
                        onClick={() => {this.setState({isDropdownActive: !this.state.isDropdownActive})}}
                        ref={el => this.trigger = el}
                    >
                        <h3 className={style['header-dropdown__ripple__trigger__text']}>{nav.title}</h3>
                        <i className={`fas fa-caret-down ${style['header-dropdown__ripple__trigger__icon']}`} aria-hidden/>
                    </button>
                </RippleLayout>
                <div className={`${style['header-dropdown__content']}`} ref={content => this.dropdown = content}>
                    {nav.content.map((contentItem, contentIndex) => {
                        return (
                            <Link
                                className={`${style['header-dropdown__content__link']}`}
                                href={contentItem.link}
                                key={contentIndex}
                                onClick={this.props['closeOnClick'] ? () => {this.setState({isDropdownActive: false})} : () => {}}
                                draggable='false'
                            >
                                <Button
                                    className={`${style['header-dropdown__content__link__button']}`}
                                    title={contentItem.title}
                                    displayType='text'
                                    visuals={{
                                        colorBasic: ['var(--colorFontCommon)'],
                                        colorFontNotContained: ['var(--colorFontTitle)'],
                                        colorRippleTint: ['var(--colorFontTitle)'],
                                        borderRadius: '0',
                                        height: '4rem'
                                    }}
                                    tabIndex={'-1'}
                                />
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }
}