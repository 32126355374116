import React, { useEffect, useRef, useState } from 'react'
import { NAV_LINKS } from '../headerNav';
import styles from './MobileHeader.module.scss'
import Button from "../../../../../components/ripple/Button";
import Link from "next/link";
import { DEBUG, NAV_DASHBOARD } from "../../../../../config";
import SalesContacts from "../components/SalesContacts";
import Logo from "../components/Logo";
import UserTrigger from "../components/UserTrigger";
import NotificationTrigger from "../components/NotificationTrigger";

function MobileHeader({user, isMobile}) {

    const [revealNavigation, setRevealNavigation] = useState(false);

    const trigger = useRef();
    const nav = useRef();

    useEffect(() => {
        return () => window.removeEventListener('touchend', handleClickOutsideNav);
    }, []);

    const handleClickOutsideNav = e => {
        const notNav = !e.target.closest('.' + nav.current?.classList[0]);
        const notTrigger = !trigger.current?.contains(e.target)
        if (notNav && notTrigger) setRevealNavigation(false)
    };

    useEffect(() => {
        if (revealNavigation) {
            window.addEventListener('touchend', handleClickOutsideNav);
            setTimeout(() => document.body.style.overflow = 'hidden', 0);
        }
        if (!revealNavigation) {
            window.removeEventListener('touchend', handleClickOutsideNav);
            setTimeout(() => document.body.style.overflow = 'auto', 0);
        }
    }, [revealNavigation]);

    const openNav = () => setRevealNavigation(!revealNavigation);

    const onNavClick = () => setTimeout(() => setRevealNavigation(false), 300);

    const itemButton = (item, index) => {

        if (!!item.dev && !DEBUG) return null;

        return (
            <Link className={styles.nav__container__links__link} href={item.link || '/'} key={index}>
                <Button
                    style={{'--m-nav-color': item.color}}
                    title={item.title}
                    className={styles.nav__container__links__link__button}
                    iconLeft={'fas fa-' + item.icon}
                    displayType='text'
                    onClick={() => {
                        if (item.onClick) item.onClick();
                        onNavClick()
                    }}
                    visuals={{
                        colorBasic: item.color ? [item.color] : ['var(--colorFontCommon)', 'var(--colorFontTitle)'],
                        animationDuration: '300ms',
                        borderRadius: '0',
                        height: '5.5rem',
                    }}
                />
            </Link>
        );
    };

    const renderNavItems = () => {

        const routes = [
            ...[
                !!Object.keys(user || {})?.length
                    ? {title: 'Dashboard', link: NAV_DASHBOARD, icon: 'columns', showIcon: true}
                    : {}
            ],
            ...NAV_LINKS
        ]

        return (
            <>
                <Button
                    aria-label='Close navigation menu'
                    className={styles.nav__back}
                    iconLeft={'fas fa-chevron-left'}
                    displayType='text'
                    onClick={onNavClick}
                    visuals={{
                        colorBasic: ['var(--colorFontCommon)', 'var(--colorFontTitle)'],
                        animationDuration: '300ms',
                        borderRadius: '0',
                        height: '5.5rem',
                    }}
                />
                <div className={styles.nav__container}>
                    <div className={styles.nav__container__links}>
                        {
                            routes.map((item, index) => {
                                if (!!(item.link || item.onClick) && !item.content) {
                                    return itemButton(item, index)
                                } else if (!item.link && !!item.content) {
                                    return item.content.map((contentItem, contentIndex) => {
                                        return itemButton(contentItem, contentIndex + contentItem.title)
                                    })
                                }
                            })
                        }
                    </div>
                    <SalesContacts isMobile={isMobile}/>
                </div>
            </>
        )
    };

    const navState = revealNavigation ? ` ${styles['header--show-nav']}` : '';


    return (
        <header className={`${styles['header']}${navState}`}>
            <button
                className={styles.header__trigger}
                aria-label='Open navigation menu'
                onClick={openNav}
                ref={trigger}
            >
                <i className={`fas fa-bars ${styles.header__trigger__icon}`} aria-hidden="true"/>
            </button>
            <div className={styles.header__logo}><Logo/></div>
            <div className={styles.header__user}>
                <NotificationTrigger/>
                <UserTrigger isMobile={true} user={user}/>
            </div>
            <nav className={styles.header__nav} ref={nav}>
                {renderNavItems()}
            </nav>
        </header>
    );
}

export default MobileHeader