import React, { useEffect, useRef, useState } from 'react'
import { NAV_LINKS } from '../headerNav'
import styles from './DesktopHeader.module.scss'
import HeaderDropdown from "./components/HeaderDropdown";
import Link from 'next/link'
import { DEBUG, NAV_DASHBOARD, NAV_SIGN_IN } from "../../../../../config";
import SalesContacts from "../components/SalesContacts";
import Logo from "../components/Logo";
import UserTrigger from "../components/UserTrigger";
import NotificationTrigger from "../components/NotificationTrigger";

function NavLink({link, padding}) {

    if (!!link.dev && !DEBUG) return null;

    return (
        (<Link
            href={link.link || '/'}
            draggable='false'
            style={{
                '--h-nav-pad': padding,
                '--h-nav-color': link.color
            }}
            onClick={link.onClick}
            className={`${styles['nav__item']} ${styles['nav__link']}`}>

            {link.showIcon && <i className={`fas fa-${link.icon}`} aria-hidden="true"/>}
            {!link.hideTitle && <h3>{link.title}</h3>}
            {!!link.beta && <span className={styles.nav__item__beta}>Beta</span>}

        </Link>)
    );
}

function DesktopHeader({user, isMobile, width}) {

    const isAuth = !!Object.keys(user || {})?.length;

    const [sideWidth, setSideWidth] = useState();
    const [fillerWidth, setFillerWidth] = useState();

    let headerLogo = useRef();
    let headerUser = useRef();
    let sales = useRef();

    useEffect(() => {
        if (!isMobile) {

            if (width > 1111) {
                const logoWidth = headerLogo.current.offsetWidth;
                const userWidth = headerUser.current.offsetWidth;
                setSideWidth(logoWidth > userWidth ? logoWidth : userWidth);
            } else setSideWidth(0);

            setFillerWidth(width > 1430 ? sales.current.offsetWidth : 0)
        }
    }, [width])

    const renderNav = () => {
        return (
            <nav className={styles.nav}>
                {
                    NAV_LINKS.map((nav, navIndex) => {
                        switch (true) {
                            case (nav.link || nav.onClick) && (!nav.content || !nav.content.length):
                                return <NavLink link={nav} key={navIndex}/>;
                            case nav.content && !!nav.content.length :
                                return <HeaderDropdown nav={nav} index={navIndex} key={navIndex} closeOnClick={true}/>;
                            default:
                                return 'data_error'
                        }
                    })
                }
            </nav>
        )
    };

    const renderUserSection = () => {
        return (
            <div className={styles.user}>
                <NavLink
                    link={{
                        link: isAuth ? NAV_DASHBOARD : NAV_SIGN_IN,
                        icon: 'fas fa-columns',
                        title: 'Dashboard',
                        showIcon: true,
                    }}
                    padding='5px 10px 5px 15px'
                />
                <NotificationTrigger/>
                <UserTrigger isMobile={false} user={user}/>
            </div>
        )
    }

    return (
        <header
            className={styles.container}
            style={{
                '--side-width': `${sideWidth}px`,
                '--filler-width': `${fillerWidth}px`,
            }}
        >
            <div className={styles.container__logo} ref={headerLogo}><Logo/></div>
            <div className={styles.container__center}>
                <div className={styles.container__center__filler}/>
                <div className={styles.container__center__nav}>{renderNav()}</div>
                <div className={styles.container__center__contacts} ref={sales}>
                    <SalesContacts isMobile={isMobile}/>
                </div>
            </div>
            <div className={styles.container__user} ref={headerUser}>{renderUserSection()}</div>
        </header>
    )

}

export default DesktopHeader